// locationData.js
export const locationData = {
  "प्रदेश १": {
    भोजपुर: [
      "भोजपुर नगरपालिका",
      "षडानन्द नगरपालिका",
      "अरुण गाउँपालिका",
      "हटैयाडाँडा गाउँपालिका",
      "साल्पासिलिचो गाउँपालिका",
      "ट्याम्केमैयुङ गाउँपालिका",
    ],
    धनकुटा: [
      "धनकुटा नगरपालिका",
      "महालक्ष्मी नगरपालिका",
      "पाख्रिबास नगरपालिका",
      "छथर जोरपाटी गाउँपालिका",
      "चौविसे गाउँपालिका",
    ],
    इटहरी: [
      "इटहरी उपमहानगरपालिका",
      "धरान उपमहानगरपालिका",
      "बिराटनगर महानगरपालिका",
    ],
    इलाम: [
      "इलाम नगरपालिका",
      "सूर्योदय नगरपालिका",
      "माङसेबुङ गाउँपालिका",
      "रोङ गाउँपालिका",
      "माई नगरपालिक",
    ],
    झापा: [
      "मेचीनगर नगरपालिका",
      "बिर्तामोड नगरपालिका",
      "दमक नगरपालिका",
      "भद्रपुर नगरपालिका",
      "अर्जुनधारा नगरपालिका",
      "कन्काई नगरपालिका",
      "गौरादह नगरपालिका",
      "गौरिगञ्ज गाउँपालिका",
      "बाह्रदशी गाउँपालिका",
      "झापा गाउँपालिका",
      "कमल गाउँपालिका",
      "हल्दिबारी गाउँपालिका",
    ],
    खोटाङ: [
      "खोटाङ नगरपालिका",
      "जन्तेढुंगा गाउँपालिका",
      "ऐसेलुखर्क गाउँपालिका",
      "दिक्तेल रुपाकोट मझुवागढी नगरपालिका",
      "रावा बेसी गाउँपालिका",
    ],
    मोरङ: [
      "विराटनगर महानगरपालिका",
      "सुन्दरहरैँचा नगरपालिका",
      "बेलबारी नगरपालिका",
      "पथरी शनिश्चरे नगरपालिका",
      "उर्लाबारी नगरपालिका",
      "रतुवामाई नगरपालिका",
      "सुनवर्षी नगरपालिका",
    ],
    ओखलढुंगा: ["ओखलढुंगा नगरपालिका", "सिद्धिचरण नगरपालिका", "मोलुङ गाउँपालिका"],
    पाँचथर: [
      "फिदिम नगरपालिका",
      "फालेलुङ गाउँपालिका",
      "फालोट गाउँपालिका",
      "हिलिहाङ गाउँपालिका",
    ],
    सङ्खुवासभा: [
      "खाँदबारी नगरपालिका",
      "चिचिला गाउँपालिका",
      "पाङ्ग्मा गाउँपालिका",
    ],
    सोलुखुम्बु: [
      "सोलु नगरपालिक",
      "मोटो गाउँपालिका",
      "खुम्जुङ गाउँपालिका",
      "पाथिभरा नगरपालिका",
    ],
    सुनसरी: ["धरान उपमहानगरपालिका", "ईनरुवा नगरपालिका", "देवाङ्गु गाउँपालिका"],
    ताप्लेजुङ: ["ताप्लेजुङ नगरपालिका", "अकाबरे गाउँपालिका"],
    तेह्रथुम: [
      "म्याङलुङ नगरपालिका",
      "लालीगुराँस नगरपालिका",
      "फेदाप गाउँपालिका",
    ],
    उदयपुर: ["त्रियुगा नगरपालिका", "कटारी नगरपालिका"],
  },
  "मधेश प्रदेश": {
   "सप्तरी": [
    "राजविराज नगरपालिका",
    "डाक्नेश्वरी नगरपालिका",
    "हनुमाननगर कंकालिनी नगरपालिका",
    "कञ्चनरुप नगरपालिका",
    "शम्भुनाथ नगरपालिका",
    "खडक नगरपालिका",
    "सुरुङ्गा नगरपालिका",
    "बोधे बरसाइन नगरपालिका",
    "महादेवा गाउँपालिका",
    "तिरहुत गाउँपालिका",
    "तिलाठी कोइलाडी गाउँपालिका",
    "राजगढ गाउँपालिका",
    "अग्निसाइर कृष्णासवरन गाउँपालिका",
    "छिन्नमस्ता गाउँपालिका",
    "बिष्णुपुर गाउँपालिका",
  ],
  "सिराहा": [
    "लहान नगरपालिका",
    "गोलबजार नगरपालिका",
    "मिर्चैया नगरपालिका",
    "सिराहा नगरपालिका",
    "धनगढीमाई नगरपालिका",
    "कर्जन्हा नगरपालिका",
    "सुखीपुर नगरपालिका",
    "भगवानपुर गाउँपालिका",
    "बरियारपट्टी गाउँपालिका",
    "लक्ष्मीपुर पतारी गाउँपालिका",
    "नवराजपुर गाउँपालिका",
    "औरही गाउँपालिका",
    "नरहा गाउँपालिका",
    "अर्नमा गाउँपालिका",
  ],
  "धनुषा": [
    "जनकपुरधाम उपमहानगरपालिका",
    "क्षिरेश्वरनाथ नगरपालिका",
    "मिथिला नगरपालिका",
    "मिथिला बिहारी नगरपालिका",
    "सबैला नगरपालिका",
    "धनुषाधाम नगरपालिका",
    "नगराइन नगरपालिका",
    "विदेह नगरपालिका",
    "शहिदनगर नगरपालिका",
    "कमला नगरपालिका",
    "जनकनन्दिनी गाउँपालिका",
    "मुखियापट्टी मुसहरनिया गाउँपालिका",
    "बटेश्वर गाउँपालिका",
    "लक्ष्मीनिया गाउँपालिका",
    "मिथिला बिहारी गाउँपालिका",
  ],
  "महोत्तरी": [
    "जलेश्वर नगरपालिका",
    "गौशाला नगरपालिका",
    "मनरा शिसवा नगरपालिका",
    "बर्दिबास नगरपालिका",
    "बलवा नगरपालिका",
    "लोहरपट्टी नगरपालिका",
    "रामगोपालपुर नगरपालिका",
    "औरही नगरपालिका",
    "मटिहानी नगरपालिका",
    "एकडारा गाउँपालिका",
    "साम्सी गाउँपालिका",
    "महेन्द्रनगर गाउँपालिका",
    "सोनमा गाउँपालिका",
    "पिपरा गाउँपालिका",
    "मनराशिसवा गाउँपालिका",
  ],
  "सर्लाही": [
    "मलंगवा नगरपालिका",
    "हरिपुर्वा नगरपालिका",
    "हरिवन नगरपालिका",
    "बरहथवा नगरपालिका",
    "लालबन्दी नगरपालिका",
    "ईश्वरपुर नगरपालिका",
    "बलरा नगरपालिका",
    "विष्णु गाउँपालिका",
    "पर्सा गाउँपालिका",
    "कविलासी नगरपालिका",
    "बागमती नगरपालिका",
    "चक्रघट्टा गाउँपालिका",
    "हरकठवा गाउँपालिका",
    "रामनगर गाउँपालिका",
    "कविलासी गाउँपालिका",
  ],
  "रौतहट": [
    "गौर नगरपालिका",
    "गरुडा नगरपालिका",
    "कदामाई नगरपालिका",
    "चन्द्रपुर नगरपालिका",
    "राजपुर नगरपालिका",
    "बौधीमाई नगरपालिका",
    "दुर्गाभगवती नगरपालिका",
    "परोहा नगरपालिका",
    "देवाही गोनाही नगरपालिका",
    "ईशनाथ नगरपालिका",
    "गढीमाई नगरपालिका",
    "यमुनामाई गाउँपालिका",
    "माधव नारायण गाउँपालिका",
    "राजदेवी गाउँपालिका",
  ],
  "बारा": [
    "कलैया उपमहानगरपालिका",
    "सिम्रौनगढ नगरपालिका",
    "कोल्हवी नगरपालिका",
    "बारागढी नगरपालिका",
    "परवानीपुर गाउँपालिका",
    "करैयामाई गाउँपालिका",
    "प्रसौनी गाउँपालिका",
    "फेटा गाउँपालिका",
    "पचरौता नगरपालिका",
    "देउरावन गाउँपालिका",
    "निजगढ नगरपालिका",
  ],
  "पर्सा": [
    "वीरगञ्ज महानगरपालिका",
    "पोखरिया नगरपालिका",
    "बहुदरमाई नगरपालिका",
    "सखुवा प्रसौनी गाउँपालिका",
    "जगरनाथपुर गाउँपालिका",
    "धोबिनी गाउँपालिका",
    "पकाहामैनपुर गाउँपालिका",
    "पटेर्वा सुगौली गाउँपालिका",
    "विन्दबासिनी गाउँपालिका",
    "छिपहरमाई गाउँपालिका",
    "ठोरी गाउँपालिका",
  ],
  },
  "बागमती प्रदेश": {
   "भक्तपुर": [
    "भक्तपुर नगरपालिका",
    "चाँगुनारायण नगरपालिका",
    "मध्यपुर थिमि नगरपालिका",
    "सुर्यविनायक नगरपालिका",
  ],
  "धादिङ": [
    "धादिङबेसी नगरपालिका",
    "गंगाजमुना गाउँपालिका",
    "ज्वालामुखी गाउँपालिका",
    "गजुरी गाउँपालिका",
    "गल्छी गाउँपालिका",
    "थाक्रे गाउँपालिका",
    "निलकण्ठ नगरपालिका",
    "रुबीभ्याली गाउँपालिका",
    "सिद्धलेक गाउँपालिका",
    "त्रिपुरासुन्दरी गाउँपालिका",
    "बेनीघाट रोराङ गाउँपालिका",
  ],
  "काठमाडौँ": [
    "काठमाडौँ महानगरपालिका",
    "कागेश्वरी मनहरा नगरपालिका",
    "गोकर्णेश्वर नगरपालिका",
    "चन्द्रागिरि नगरपालिका",
    "कीर्तिपुर नगरपालिका",
    "शङ्खरापुर नगरपालिका",
    "टोखा नगरपालिका",
    "बुढानिलकण्ठ नगरपालिका",
    "दक्षिणकाली नगरपालिका",
  ],
  "काभ्रेपलाञ्चोक": [
    "धुलिखेल नगरपालिका",
    "पनौती नगरपालिका",
    "पनौती नगरपालिका",
    "बनेपा नगरपालिका",
    "पाँचखाल नगरपालिका",
    "मण्डनदेउपुर नगरपालिका",
    "नमोबुद्ध नगरपालिका",
    "महाभारत गाउँपालिका",
    "खानीखोला गाउँपालिका",
    "बेथानचोक गाउँपालिका",
    "चौंरीदेउराली गाउँपालिका",
    "तेमाल गाउँपालिका",
    "रोशी गाउँपालिका",
  ],
  "ललितपुर": [
    "ललितपुर महानगरपालिका",
    "गोदावरी नगरपालिका",
    "महालक्ष्मी नगरपालिका",
    "कोञ्ज्योसोम गाउँपालिका",
    "ललितपुर महानगरपालिका",
    "महालक्ष्मी नगरपालिका",
  ],
  "मकवानपुर": [
    "हेटौँडा उपमहानगरपालिका",
    "थाहा नगरपालिका",
    "भीमफेदी गाउँपालिका",
    "मनहरि गाउँपालिका",
    "इन्द्रसरोवर गाउँपालिका",
    "मकवानपुरगढी गाउँपालिका",
    "बकैया गाउँपालिका",
    "कैलाश गाउँपालिका",
    "राक्सिराङ गाउँपालिका",
  ],
  "नुवाकोट": [
    "विदुर नगरपालिका",
    "बेलकोटगढी नगरपालिका",
    "दुप्चेश्वर गाउँपालिका",
    "लिखु गाउँपालिका",
    "तादी गाउँपालिका",
    "ककनी गाउँपालिका",
    "मेघाङ गाउँपालिका",
    "शिवपुरी गाउँपालिका",
    "पञ्चकन्या गाउँपालिका",
    "तारकेश्वर गाउँपालिका",
    "किस्पाङ गाउँपालिका",
  ],
  "रसुवा": [
    "कालिका गाउँपालिका",
    "नौकुण्ड गाउँपालिका",
    "उत्तरी गाउँपालिका",
    "गोसाइँकुण्ड गाउँपालिका",
    "आमाछोदिङमो गाउँपालिका",
  ],
  "सिन्धुपाल्चोक": [
    "चौतारा साँगाचोकगढी नगरपालिका",
    "बलेफी गाउँपालिका",
    "जुगल गाउँपालिका",
    "सुनकोशी गाउँपालिका",
    "ईन्द्रावती गाउँपालिका",
    "बलेफी गाउँपालिका",
    "माण्डनदेउपुर नगरपालिका",
    "नागार्जुन नगरपालिका",
  ],
  "चितवन": [
    "भरतपुर महानगरपालिका",
    "रत्ननगर नगरपालिका",
    "कृष्णनगर नगरपालिका",
    "माडी नगरपालिका",
    "राप्ती नगरपालिका",
    "खैरहनी नगरपालिका",
    "कालिका नगरपालिका",
    "इच्छाकामना गाउँपालिका",
  ],
  "दोलखा": [
    "भीमेश्वर नगरपालिका",
    "जिरी नगरपालिका",
    "गौरिशंकर गाउँपालिका",
    "बिगु गाउँपालिका",
    "तामाकोशी गाउँपालिका",
    "मेलुङ गाउँपालिका",
    "शैलुङ गाउँपालिका",
  ],
  "रामेछाप": [
    "मन्थली नगरपालिका",
    "लिखु तामाकोशी गाउँपालिका",
    "खाँडादेवी गाउँपालिका",
    "उमाकुण्ड गाउँपालिका",
    "रामेछाप नगरपालिका",
    "गोकुलगंगा गाउँपालिका",
    "सुनापति गाउँपालिका",
  ],
  "सिन्धुली": [
    "कमलामाई नगरपालिका",
    "दुधौली नगरपालिका",
    "गोलन्जोर गाउँपालिका",
    "हरीहरपुरगढी गाउँपालिका",
    "मरीण गाउँपालिका",
    "सुनकोशी गाउँपालिका",
    "फिक्कल गाउँपालिका",
  ],
  },
  "गण्डकी प्रदेश": {
   "बागलुङ": [
    "बागलुङ नगरपालिका",
    "ढोरपाटन नगरपालिका",
    "काठेखोला गाउँपालिका",
    "वरेङ गाउँपालिका",
    "तमानखोला गाउँपालिका",
    "ताराखोला गाउँपालिका",
    "निसीखोला गाउँपालिका",
    "वडिगाड गाउँपालिका",
    "बुर्तिवाङ नगरपालिका",
  ],
  "गोरखा": [
    "गोरखा नगरपालिका",
    "पालुङटार नगरपालिका",
    "बारपाक सुलिकोट गाउँपालिका",
    "आरूघाट गाउँपालिका",
    "शहिद लखन गाउँपालिका",
    "गण्डकी गाउँपालिका",
    "आरूचौदेरी गाउँपालिका",
    "भीमसेन थापा गाउँपालिका",
    "गुल्मी दरबार गाउँपालिका",
  ],
  "कास्की": [
    "पोखरा महानगरपालिका",
    "अन्नपूर्ण गाउँपालिका",
    "माछापुच्छ्रे गाउँपालिका",
    "मादी गाउँपालिका",
    "रूपा गाउँपालिका",
  ],
  "लमजुङ": [
    "बेसीशहर नगरपालिका",
    "सुन्दरबजार नगरपालिका",
    "राइनास नगरपालिका",
    "मर्स्याङ्दी गाउँपालिका",
    "मध्यनेपाल नगरपालिका",
    "दोर्दी गाउँपालिका",
    "क्व्होलासोथार गाउँपालिका",
  ],
  "मनाङ": [
    "चामे गाउँपालिका",
    "नार्पा भुमी गाउँपालिका",
    "नासों गाउँपालिका",
    "मनाङ ङिस्याङ गाउँपालिका",
  ],
  "म्याग्दी": [
    "बेनी नगरपालिका",
    "अन्नपूर्ण गाउँपालिका",
    "धवलागिरी गाउँपालिका",
    "रघुगंगा गाउँपालिका",
    "मंगला गाउँपालिका",
    "मालिका गाउँपालिका",
  ],
  "मुस्ताङ": [
    "लो-घेकर दामोदरकुण्ड गाउँपालिका",
    "लोमन्थाङ गाउँपालिका",
    "थासाङ गाउँपालिका",
    "घरपझोङ गाउँपालिका",
    "वारागुङ मुक्तिक्षेत्र गाउँपालिका",
  ],
  "नवलपरासी (बर्दघाट सुस्ता पूर्व)": [
    "कावासोती नगरपालिका",
    "गैंडाकोट नगरपालिका",
    "देवचुली नगरपालिका",
    "बौदीकाली गाउँपालिका",
    "विनयी त्रिवेणी गाउँपालिका",
    "बुलिङटार गाउँपालिका",
    "हुप्सेकोट गाउँपालिका",
  ],
  "पर्वत": [
    "कुश्मा नगरपालिका",
    "फलेवास नगरपालिका",
    "महाशिला गाउँपालिका",
    "पैयुँ गाउँपालिका",
    "जलजला गाउँपालिका",
    "मोदी गाउँपालिका",
    "विहादी गाउँपालिका",
  ],
  "स्याङ्जा": [
    "पुतलीबजार नगरपालिका",
    "वालिङ नगरपालिका",
    "चापाकोट नगरपालिका",
    "गल्याङ नगरपालिका",
    "भीरकोट नगरपालिका",
    "आँधिखोला गाउँपालिका",
    "हरिनास गाउँपालिका",
    "कालीगण्डकी गाउँपालिका",
  ],
  "तनहुँ": [
    "भानु नगरपालिका",
    "व्यास नगरपालिका",
    "शुक्लागण्डकी नगरपालिका",
    "आँबुखैरेनी गाउँपालिका",
    "म्याग्दे गाउँपालिका",
    "बन्दीपुर गाउँपालिका",
    "रिशिङ्ग गाउँपालिका",
    "घिरिङ्ग गाउँपालिका",
    "देवघाट गाउँपालिका",
  ],
  },
  "लुम्बिनी प्रदेश": {
    "अर्घाखाँची": [
    "सन्धिखर्क नगरपालिका",
    "छत्रदेव गाउँपालिका",
    "पाणिनी गाउँपालिका",
    "शितगंगा नगरपालिका",
    "मालारानी गाउँपालिका",
  ],
  "बाँके": [
    "नेपालगञ्ज उपमहानगरपालिका",
    "कोहलपुर नगरपालिका",
    "राप्तीसोनारी गाउँपालिका",
    "बाके गाउँपालिका",
    "डुडुवा गाउँपालिका",
    "जानकी गाउँपालिका",
    "खजुरा गाउँपालिका",
  ],
  "बर्दिया": [
    "गुलरिया नगरपालिका",
    "राजापुर नगरपालिका",
    "बारबर्दिया नगरपालिका",
    "थाकुरबाबा नगरपालिका",
    "बासगढी नगरपालिका",
    "गेरुवा गाउँपालिका",
    "मधुवन नगरपालिका",
  ],
  "दाङ": [
    "घोराही उपमहानगरपालिका",
    "लमही नगरपालिका",
    "तुल्सीपुर उपमहानगरपालिका",
    "गढवा गाउँपालिका",
    "राजपुर गाउँपालिका",
    "राप्ती गाउँपालिका",
    "बबई गाउँपालिका",
    "शान्तिनगर गाउँपालिका",
    "दंगीशरण गाउँपालिका",
  ],
  "गुल्मी": [
    "रेसुङ्गा नगरपालिका",
    "मुसिकोट नगरपालिका",
    "इशमा गाउँपालिका",
    "मदाने गाउँपालिका",
    "धुर्कोट गाउँपालिका",
    "छत्रकोट गाउँपालिका",
    "गुल्मी दरबार गाउँपालिका",
    "मालिका गाउँपालिका",
    "कालीगण्डकी गाउँपालिका",
    "रुरुक्षेत्र गाउँपालिका",
    "सत्यवती गाउँपालिका",
    "चन्द्रकोट गाउँपालिका",
    "सिमलटारी गाउँपालिका",
  ],
  "कपिलवस्तु": [
    "कपिलवस्तु नगरपालिका",
    "शिवराज नगरपालिका",
    "बुद्धभूमि नगरपालिका",
    "बाणगंगा नगरपालिका",
    "महाराजगञ्ज नगरपालिका",
    "शुद्धोधन गाउँपालिका",
    "यसोधरा गाउँपालिका",
    "मायादेवी गाउँपालिका",
  ],
  "प्युठान": [
    "स्वर्गद्वारी नगरपालिका",
    "माण्डवी गाउँपालिका",
    "सरुमारानी गाउँपालिका",
    "झिमरुक गाउँपालिका",
    "गौमुखी गाउँपालिका",
    "नौबहिनी गाउँपालिका",
    "ऐरावती गाउँपालिका",
    "जलुके गाउँपालिका",
  ],
  "रोल्पा": [
    "लिवाङ नगरपालिका",
    "रुन्टीगढी गाउँपालिका",
    "सुनिलस्मृति गाउँपालिका",
    "त्रिवेणी गाउँपालिका",
    "मादी गाउँपालिका",
    "लुङ्री गाउँपालिका",
    "परिवर्तन गाउँपालिका",
    "गंगादेव गाउँपालिका",
    "थवाङ गाउँपालिका",
    "सुलिचौर नगरपालिका",
  ],
  "रुकुम पूर्व": [
    "भूमे गाउँपालिका",
    "पुथा उत्तरगंगा गाउँपालिका",
    "माघा गाउँपालिका",
    "सिस्ने गाउँपालिका",
  ],
  "रुपन्देही": [
    "बुटवल उपमहानगरपालिका",
    "सिद्धार्थनगर नगरपालिका",
    "तिलोत्तमा नगरपालिका",
    "लुम्बिनी सांस्कृतिक नगरपालिका",
    "देवदह नगरपालिका",
    "मर्चवारी गाउँपालिका",
    "सियारी गाउँपालिका",
    "ओमसतिया गाउँपालिका",
    "मैदावल गाउँपालिका",
    "कोटहीमाई गाउँपालिका",
    "रोहिणी गाउँपालिका",
  ],
  "नवलपरासी (बर्दघाट सुस्ता पश्चिम)": [
    "प्रतापपुर गाउँपालिका",
    "पाल्हीनन्दन गाउँपालिका",
    "सरावल गाउँपालिका",
    "प्रगति नगरपालिका",
    "बर्दघाट नगरपालिका",
    "सुस्ता गाउँपालिका",
    "सुनवल नगरपालिका",
    "सरावल गाउँपालिका",
  ],
  },
  "कर्णाली प्रदेश": {
    "दैलेख": [
    "दैलेख नगरपालिका",
    "दुल्लू नगरपालिका",
    "आठबीस नगर गाउँपालिका",
    "भैरवी गाउँपालिका",
    "भैरवी नगरपालिका",
    "महाबु गाउँपालिका",
    "बडिकेदार गाउँपालिका",
    "तुनीबास्कोट गाउँपालिका",
    "राकम कर्णाली गाउँपालिका"
  ],
  "डोल्पा": [
    "ठुलीभेरी नगरपालिका",
    "त्रिपुरासुन्दरी नगरपालिका",
    "डोल्पो बुद्ध गाउँपालिका",
    "जगदुल्ला गाउँपालिका",
    "काईके गाउँपालिका",
    "मुड्केचुला गाउँपालिका",
    "से फोक्सुन्डो गाउँपालिका"
  ],
  "हुम्ला": [
    "सिमकोट गाउँपालिका",
    "खार्पुनाथ गाउँपालिका",
    "नाम्खा गाउँपालिका",
    "चंखेली गाउँपालिका",
    "अदाकोट गाउँपालिका",
    "सर्केगाड गाउँपालिका",
    "ताँजाकोट गाउँपालिका"
  ],
  "जाजरकोट": [
    "चौगड गाउँपालिका",
    "भेरी नगरपालिका",
    "कुसे गाउँपालिका",
    "जुनीचाँदे गाउँपालिका",
    "नलगाड नगरपालिका"
  ],
  "जुम्ला": [
    "चन्दननाथ नगरपालिका",
    "काँडाबास गाउँपालिका",
    "हिमा गाउँपालिका",
    "तिला गाउँपालिका",
    "सिंजा गाउँपालिका",
    "गुठिचौर गाउँपालिका",
    "तातोपानी गाउँपालिका",
    "पातारासी गाउँपालिका"
  ],
  "कालिकोट": [
    "खाँडाचक्र नगरपालिका",
    "नरहरिनाथ गाउँपालिका",
    "पचालझरना गाउँपालिका",
    "कालिका गाउँपालिका",
    "महाबु गाउँपालिका",
    "रास्कोट नगरपालिका",
    "तिलागुफा नगरपालिका",
    "सिद्धिनाथ गाउँपालिका"
  ],
  "मुगु": [
    "चायन्नाथ रारा नगरपालिका",
    "खत्याड गाउँपालिका",
    "मुगुम कार्मारोंग गाउँपालिका",
    "सोरु गाउँपालिका"
  ],
  "रुकुम पश्चिम": [
    "आठबीसकोट नगरपालिका",
    "त्रिवेणी गाउँपालिका",
    "सानीभेरी गाउँपालिका",
    "बाफिकोट गाउँपालिका",
    "रुंख नगरपालिक",
    "चौरजहारी नगरपालिका",
    "भुमे गाउँपालिका"
  ],
  "सल्यान": [
    "बागचौर नगरपालिका",
    "शारदा नगरपालिका",
    "बनगाड कुपिन्डे नगरपालिका",
    "त्रिबेणी गाउँपालिका",
    "कपुरकोट गाउँपालिका",
    "कुमाख गाउँपालिका",
    "कालीमाटी गाउँपालिका"
  ],
  "सुर्खेत": [
    "वीरेन्द्रनगर नगरपालिका",
    "पञ्चपुरी नगरपालिका",
    "बराहताल गाउँपालिका",
    "चिङ्गाड गाउँपालिका",
    "गुर्भाकोट नगरपालिका",
    "लेकबेसी नगरपालिका",
    "भेरीगंगा नगरपालिका"
  ],
  },
  "सुदूरपश्चिम प्रदेश": {
    "अछाम": [
        "मंगलसेन नगरपालिका",
        "कमलबजार नगरपालिका",
        "पञ्चदेवल विनायक नगरपालिका",
        "तुरमाखाँद गाउँपालिका",
        "चौरपाटी गाउँपालिका",
        "रामारोशन गाउँपालिका",
        "ढकारी गाउँपालिका",
        "बान्निगढी जयगढ गाउँपालिका",
        "मेल्लेख गाउँपालिका",
        "साफेबगर नगरपालिका"
      ],
      "बझाङ": [
        "बित्थडचिर गाउँपालिका",
        "छबिसपाथिभेरा गाउँपालिका",
        "तल्कोट गाउँपालिका",
        "जयपृथ्वी नगरपालिका",
        "खप्तडछान्ना गाउँपालिका",
        "सयापाटा गाउँपालिका",
        "थलारा गाउँपालिका",
        "मष्टा गाउँपालिका",
        "सुर्मा गाउँपालिका",
        "वित्तामोड गाउँपालिका",
        "वित्थडचिर गाउँपालिका"
      ],
      "बाजुरा": [
        "बडीमालिका नगरपालिका",
        "त्रिवेणी नगरपालिका",
        "गौमुल गाउँपालिका",
        "बुढीनन्दा नगरपालिका",
        "हिमाली गाउँपालिका",
        "जगन्नाथ गाउँपालिका",
        "स्वामीकार्तिक गाउँपालिका",
        "खप्तडछेडडाँडा गाउँपालिका"
      ],
      "डडेलधुरा": [
        "आलिताल गाउँपालिका",
        "अजयमेरु गाउँपालिका",
        "भागेश्वर गाउँपालिका",
        "नवदुर्गा गाउँपालिका",
        "गन्यापधुरा गाउँपालिका",
        "अमरगढी नगरपालिका",
        "परशुराम नगरपालिका"
      ],
      "डोटी": [
        "दिपायल सिलगढी नगरपालिका",
        "बोगटान फुड्सिल गाउँपालिका",
        "जोरायल गाउँपालिका",
        "के.आई.सिंह गाउँपालिका",
        "आदर्श गाउँपालिका",
        "पूर्वीचौकी गाउँपालिका",
        "सायल गाउँपालिका",
        "बडीकेदार गाउँपालिका",
        "पुर्विचौकी गाउँपालिका"
      ],
      "कैलाली": [
        "धनगढी उपमहानगरपालिका",
        "घोडाघोडी नगरपालिका",
        "गोदावरी नगरपालिका",
        "टीकापुर नगरपालिका",
        "लम्कीचुहा नगरपालिका",
        "भजनी नगरपालिका",
        "जोशीपुर गाउँपालिका",
        "जानकी गाउँपालिका",
        "कैलारी गाउँपालिका",
        "बर्दगोरिया गाउँपालिका",
        "मोहनी गाउँपालिका"
      ],
      "कञ्चनपुर": [
        "महाकाली नगरपालिका",
        "पुनर्वास नगरपालिका",
        "शुक्लाफाँटा नगरपालिका",
        "भिमदत्त नगरपालिका",
        "बेलौरी नगरपालिका",
        "बेलडाँडी गाउँपालिका",
        "कृष्णपुर नगरपालिका",
        "लालझाडी गाउँपालिका"
      ],
      "बैतडी": [
        "पाटन नगरपालिका",
        "दशरथचन्द नगरपालिका",
        "सिगास गाउँपालिका",
        "डीलासैनी गाउँपालिका",
        "सुर्नया गाउँपालिका",
        "पञ्चेश्वर गाउँपालिका",
        "शिवनाथ गाउँपालिका",
        "डोगडाकेदार गाउँपालिका",
        "मष्टामाण्डौ गाउँपालिका"
      ],
      "दार्चुला": [
        "शैल्यशिखर नगरपालिका",
        "महाकाली नगरपालिका",
        "मार्मा गाउँपालिका",
        "अपिहिमाल गाउँपालिका",
        "व्यास गाउँपालिका",
        "नौगाड गाउँपालिका",
        "लेकम गाउँपालिका"
      ],
  },
};
