import React, { useState } from "react";
import { NepaliDatePicker, BSDate } from "nepali-datepicker-react";
import JoditEditor from "jodit-react";
import NavBar from "../components/Navbar";
import NavigationMenu from "../components/NavigationMenu";
import { districts } from "./district";
import { locationData } from "./locationData";
import "../assets/styles/dartaform.css";
import axios from "axios";
import NepaliDate from "nepali-date";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dartaform = () => {
  const navigate = useNavigate();
  const [permanentWarning, setPermanentWarning] = useState("");
  const [temporaryWarning, setTemporaryWarning] = useState("");

  const [formData, setFormData] = useState({
    plaintiff: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      age: "",
      sex: "",
      mobileNo: "",
      citizenshipNo: "",
      citizinshipIssuedDate: "",
      grandFathersName: "",
      fathersName: "",
      temporaryAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
      permanentAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
    },
    defendent: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      age: "",
      sex: "",
      mobileNo: "",
      citizenshipNo: "",
      citizinshipIssuedDate: "",
      grandFathersName: "",
      fathersName: "",
      temporaryAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
      permanentAddress: {
        province: "",
        district: "",
        municipality: "",
        wardNo: "",
      },
    },
    caseDescription: {
      lawSuit: "",
      type: "",
      registeredDate: null,
      stage: "",
      fileNo: "",
      //   filingDate: null,
      firstDateOfPresence: null,
      timeOfPresence: "",
      registrationNo: "",
      registrationFee: "",
      subjectOfCase: "",
      description: "",
      witness: "",
    },
    judicialDetails: {
      type: "",
      court: "",
      judgeName: "",
      judgePosition: "",
      description: "",
    },
  });

  const casePhases = ["मुद्दा दर्ता", "प्रक्रिया", "तामेली", "सम्पन्न"];
  const calculateAge = (dob) => {
    if (!dob) return "";
    const today = new NepaliDate();
    const birthDate = new NepaliDate(dob);
    let age = today.getYear() - birthDate.getYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const now = new BSDate().now();
  const [date, setDate] = useState(now);

  const handleDate = (date) => {
    setDate(date);
    console.log("Date", date);
  };

  const handleInputChange = (e, section, field) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [field]: e.target.value,
      },
    });
  };

  const handleDateChange = (date, section, field) => {
    const age = calculateAge(date);
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [field]: date,
        age: age,
      },
    });
  };

  const handleAddressChange = (e, section, addressType, field) => {
    const districtValue = e.target.value;
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [addressType]: {
          ...formData[section][addressType],
          [field]: districtValue,
        },
      },
    });
  };

  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);

  const [filteredtempDistricts, setFilteredTempDistricts] = useState([]);
  const [filteredtempMunicipalities, setFilteredTempMunicipalities] = useState(
    []
  );

  // Handle province change
  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    const districts = selectedProvince
      ? Object.keys(locationData[selectedProvince])
      : [];

    setFormData((prevData) => ({
      ...prevData,
      plaintiff: {
        ...prevData.plaintiff,
        temporaryAddress: {
          ...prevData.plaintiff.temporaryAddress,
          province: selectedProvince,
          district: "",
          municipality: "",
        },
      },
    }));

    setFilteredDistricts(districts);
    setFilteredMunicipalities([]);
  };

  // Handle district change
  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    const municipalities = selectedDistrict
      ? locationData[formData.plaintiff.temporaryAddress.province][
          selectedDistrict
        ]
      : [];

    setFormData((prevData) => ({
      ...prevData,
      plaintiff: {
        ...prevData.plaintiff,
        temporaryAddress: {
          ...prevData.plaintiff.temporaryAddress,
          district: selectedDistrict,
          municipality: "",
        },
      },
    }));

    setFilteredMunicipalities(municipalities);
  };

  // Handle municipality change
  const handleMunicipalityChange = (e) => {
    const selectedMunicipality = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      plaintiff: {
        ...prevData.plaintiff,
        temporaryAddress: {
          ...prevData.plaintiff.temporaryAddress,
          municipality: selectedMunicipality,
        },
      },
    }));
  };

  // Handle Permanent province change
  const handleTempProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    const districts = selectedProvince
      ? Object.keys(locationData[selectedProvince])
      : [];

    setFormData((prevData) => ({
      ...prevData,
      plaintiff: {
        ...prevData.plaintiff,
        permanentAddress: {
          ...prevData.plaintiff.permanentAddress,
          province: selectedProvince,
          district: "",
          municipality: "",
        },
      },
    }));

    setFilteredTempDistricts(districts);
    setFilteredTempMunicipalities([]);
  };

  // Handle Permanent district change
  const handleDistrictTempChange = (e) => {
    const selectedDistrict = e.target.value;
    const municipalities = selectedDistrict
      ? locationData[formData.plaintiff.permanentAddress.province][
          selectedDistrict
        ]
      : [];

    setFormData((prevData) => ({
      ...prevData,
      plaintiff: {
        ...prevData.plaintiff,
        permanentAddress: {
          ...prevData.plaintiff.permanentAddress,
          district: selectedDistrict,
          municipality: "",
        },
      },
    }));

    setFilteredTempMunicipalities(municipalities);
  };

  // Handle Permanent municipality change
  const handleMunicipalityTempChange = (e) => {
    const selectedMunicipality = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      plaintiff: {
        ...prevData.plaintiff,
        permanentAddress: {
          ...prevData.plaintiff.permanentAddress,
          municipality: selectedMunicipality,
        },
      },
    }));
  };

  const validateForm = () => {
    const requiredFields = [
      // "plaintiff.firstName",
    ];
    for (const field of requiredFields) {
      const [section, subfield] = field.split(".");
      if (!formData[section][subfield]) {
        toast.error(`Please fill out the form details`);
        return false;
      }
    }
    return true;
  };

  const showToast = () => {
    toast.success("Darta Form is Submitted Successfully.");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const mySelectElement = document.getElementById("mySelectElement");
      if (!mySelectElement) {
        console.error('Element with ID "mySelectElement" not found.');
        return;
      }

      mySelectElement.disabled = true;

      // Make the API request
      const response = await axios.post(
        "https://backend.bardagoriyalegalsystem.com/api/v1/admin/createCase",
        formData
      );

      // Check if the response was successful (status 200 or 201)
      if (response.status === 200 || response.status === 201) {
        // Only show success message when request is successful
        toast.success("Form submitted successfully");

        // Reset form data after successful submission
        setFormData({
          plaintiff: {
            firstName: "",
            middleName: "",
            lastName: "",
            dob: "",
            age: "",
            sex: "",
            mobileNo: "",
            citizenshipNo: "",
            citizinshipIssuedDate: "",
            grandFathersName: "",
            fathersName: "",
            temporaryAddress: {
              province: "",
              district: "",
              municipality: "",
              wardNo: "",
            },
            permanentAddress: {
              province: "",
              district: "",
              municipality: "",
              wardNo: "",
            },
          },
          defendent: {
            firstName: "",
            middleName: "",
            lastName: "",
            dob: "",
            age: "",
            sex: "",
            mobileNo: "",
            citizenshipNo: "",
            citizinshipIssuedDate: "",
            grandFathersName: "",
            fathersName: "",
            temporaryAddress: {
              province: "",
              district: "",
              municipality: "",
              wardNo: "",
            },
            permanentAddress: {
              province: "",
              district: "",
              municipality: "",
              wardNo: "",
            },
          },
          caseDescription: {
            lawSuit: "",
            type: "",
            registeredDate: null,
            stage: "",
            fileNo: "",
            firstDateOfPresence: null,
            timeOfPresence: "",
            registrationNo: "",
            registrationFee: "",
            subjectOfCase: "",
            description: "",
            witness: "",
          },
          judicialDetails: {
            type: "",
            court: "",
            judgeName: "",
            judgePosition: "",
            description: "",
          },
        });

        // Redirect user after successful submission
        navigate("/muddadarta");
      } else {
        // If the status is not 200 or 201, throw an error
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Show error message only when there's an issue
      toast.error("Error submitting form");
    } finally {
      // Re-enable the select element after the request completes
      const mySelectElement = document.getElementById("mySelectElement");
      if (mySelectElement) {
        mySelectElement.disabled = false;
      }
    }
  };

  return (
    <div>
      <style>{`h2{color: #be020a; border-bottom: 2px solid #be020a; }`}</style>
      <ToastContainer />
      <NavBar />
      <NavigationMenu />
      <div className="content">
        <div className="search-barr">
          <div className="darta-form">
            <form onSubmit={handleSubmit}>
              <div className="form-section">
                <h2>प्रथम पक्ष</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="firstName">पहिलो नाम</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="पहिलो नाम"
                      value={formData.plaintiff.firstName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "firstName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="middleName">बिचको नाम</label>
                    <input
                      type="text"
                      id="middleName"
                      name="middleName"
                      placeholder="बिचको नाम"
                      value={formData.plaintiff.middleName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "middleName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">थर</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="थर"
                      value={formData.plaintiff.lastName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "lastName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dob">जन्म मिति</label>
                    <NepaliDatePicker
                      value={formData.plaintiff.dob}
                      format="YYYY-MM-DD"
                      placeholder="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(date, "plaintiff", "dob")
                      }
                      placeholderText="YYYY-MM-DD"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">उमेर</label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      placeholder="उमेर"
                      value={formData.plaintiff.age}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sex">लिङ्ग</label>
                    <select
                      id="sex"
                      name="sex"
                      placeholder="लिङ्ग चयन गर्नुहोस्"
                      value={formData.plaintiff.sex}
                      onChange={(e) => handleInputChange(e, "plaintiff", "sex")}
                    >
                      <option value="">लिङ्ग चयन गर्नुहोस्</option>
                      <option value="पुरुष">पुरुष</option>
                      <option value="महिला">महिला</option>
                      <option value="अन्य">अन्य</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobileNo">मोबाइल नं</label>
                    <input
                      type="text"
                      id="mobileNo"
                      name="mobileNo"
                      placeholder="मोबाइल नं"
                      value={formData.plaintiff.mobileNo}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "mobileNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizenshipNo">नागरिकता नं</label>
                    <input
                      type="text"
                      id="citizenshipNo"
                      name="citizenshipNo"
                      placeholder="नागरिकता नं"
                      value={formData.plaintiff.citizenshipNo}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "citizenshipNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizinshipIssuedDate">
                      नागरिकता जारी मिति
                    </label>
                    <input
                      type="text"
                      placeholder="नागरिकता जारी मिति"
                      id="citizinshipIssuedDate"
                      name="citizinshipIssuedDate"
                      value={formData.plaintiff.citizinshipIssuedDate}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "plaintiff",
                          "citizinshipIssuedDate"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="grandFathersName">बाजेको नाम</label>
                    <input
                      type="text"
                      id="grandFathersName"
                      name="grandFathersName"
                      placeholder="बाजेको नाम"
                      value={formData.plaintiff.grandFathersName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "grandFathersName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fathersName">बाबुको नाम</label>
                    <input
                      type="text"
                      placeholder="बाबुको नाम"
                      id="fathersName"
                      name="fathersName"
                      value={formData.plaintiff.fathersName}
                      onChange={(e) =>
                        handleInputChange(e, "plaintiff", "fathersName")
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="temporaryProvince">अस्थायी प्रदेश</label>
                    <select
                      id="temporaryProvince"
                      name="temporaryProvince"
                      value={formData.plaintiff.temporaryAddress.province}
                      onChange={handleProvinceChange}
                    >
                      <option value="">--अस्थायी प्रदेश छान्नुहोस्--</option>
                      {Object.keys(locationData).map((province) => (
                        <option key={province} value={province}>
                          {province}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      value={formData.plaintiff.temporaryAddress.district}
                      onChange={handleDistrictChange}
                      disabled={!filteredDistricts.length}
                    >
                      <option value="">जिल्ला चयन गर्नुहोस्</option>
                      {filteredDistricts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="temporaryMunicipality">
                      नगरपालिका/गाउँपालिका
                    </label>
                    <select
                      id="temporaryMunicipality"
                      name="municipality"
                      value={formData.plaintiff.temporaryAddress.municipality}
                      onChange={handleMunicipalityChange}
                      disabled={!filteredMunicipalities.length}
                    >
                      <option value="">
                        नगरपालिका/गाउँपालिका चयन गर्नुहोस्
                      </option>
                      {filteredMunicipalities.map((municipality) => (
                        <option key={municipality} value={municipality}>
                          {municipality}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="temporaryWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="temporaryWardNo"
                      name="temporaryWardNo"
                      placeholder="वडा नं"
                      value={formData.plaintiff.temporaryAddress.wardNo}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Inline validation logic
                        if (value === "0" || value < 0) {
                          setTemporaryWarning(
                            "वडा नं शून्य वा नकारात्मक हुनु हुँदैन।"
                          );
                        } else {
                          setTemporaryWarning("");
                        }

                        // Update formData
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          plaintiff: {
                            ...prevFormData.plaintiff,
                            temporaryAddress: {
                              ...prevFormData.plaintiff.temporaryAddress,
                              wardNo: value,
                            },
                          },
                        }));
                      }}
                    />
                    {/* Display the warning message if it exists */}
                    {temporaryWarning && (
                      <p style={{ color: "red", fontSize: "0.9rem" }}>
                        {temporaryWarning}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="permanentProvince">स्थायी प्रदेश</label>
                    <select
                      type="text"
                      id="permanentProvince"
                      name="permanentProvince"
                      placeholder="स्थायी प्रदेश"
                      value={formData.plaintiff.permanentAddress.province}
                      onChange={handleTempProvinceChange}
                    >
                      <option value="">--स्थायी प्रदेश छान्नुहोस्--</option>
                      {Object.keys(locationData).map((province) => (
                        <option key={province} value={province}>
                          {province}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      placeholder="जिल्ला"
                      value={formData.plaintiff.permanentAddress.district}
                      onChange={handleDistrictTempChange}
                      disabled={!filteredtempDistricts.length}
                    >
                      <option value="">जिल्ला चयन गर्नुहोस्</option>
                      {filteredtempDistricts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="permanentMunicipality">
                      नगरपालिका/गाउँपालिका
                    </label>
                    <select
                      type="text"
                      id="permanentMunicipality"
                      placeholder="नगरपालिका/गाउँपालिका"
                      name="municipality"
                      value={formData.plaintiff.permanentAddress.municipality}
                      onChange={handleMunicipalityTempChange}
                      disabled={!filteredtempMunicipalities.length}
                    >
                      <option value="">
                        नगरपालिका/गाउँपालिका चयन गर्नुहोस्
                      </option>
                      {filteredtempMunicipalities.map((municipality) => (
                        <option key={municipality} value={municipality}>
                          {municipality}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="permanentWardNo">वडा नं</label>
                    <input
                      type="text"
                      placeholder="वडा नं"
                      id="permanentWardNo"
                      name="wardNo"
                      value={formData.plaintiff.permanentAddress.wardNo}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Inline validation logic
                        if (value === "0" || value < 0) {
                          setPermanentWarning(
                            "वडा नं शून्य वा नकारात्मक हुनु हुँदैन।"
                          );
                        } else {
                          setPermanentWarning(""); // Clear warning if the input is valid
                        }

                        // Update formData
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          plaintiff: {
                            ...prevFormData.plaintiff,
                            permanentAddress: {
                              ...prevFormData.plaintiff.permanentAddress,
                              wardNo: value,
                            },
                          },
                        }));
                      }}
                    />
                    {/* Display the warning message if it exists */}
                    {permanentWarning && (
                      <p style={{ color: "red", fontSize: "0.9rem" }}>
                        {permanentWarning}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-section">
                <h2>प्रतिवादी</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="defendentFirstName">पहिलो नाम</label>
                    <input
                      type="text"
                      id="defendentFirstName"
                      name="firstName"
                      placeholder="पहिलो नाम"
                      value={formData.defendent.firstName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "firstName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentMiddleName">बिचको नाम</label>
                    <input
                      type="text"
                      placeholder="बिचको नाम"
                      id="defendentMiddleName"
                      name="middleName"
                      value={formData.defendent.middleName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "middleName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentLastName">थर</label>
                    <input
                      type="text"
                      id="defendentLastName"
                      name="lastName"
                      placeholder="थर"
                      value={formData.defendent.lastName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "lastName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dob">जन्म मिति</label>
                    <NepaliDatePicker
                      value={formData.defendent.dob}
                      format="YYYY-MM-DD"
                      placeholder="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(date, "defendent", "dob")
                      }
                      placeholderText="YYYY-MM-DD"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">उमेर</label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      placeholder="उमेर"
                      value={formData.defendent.age}
                      onChange={(e) => handleInputChange(e, "defendent", "age")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sex">लिङ्ग</label>
                    <select
                      id="sex"
                      name="sex"
                      placeholder="लिङ्ग चयन गर्नुहोस्"
                      value={formData.defendent.sex}
                      onChange={(e) => handleInputChange(e, "defendent", "sex")}
                    >
                      <option value="">लिङ्ग चयन गर्नुहोस्</option>
                      <option value="पुरुष">पुरुष</option>
                      <option value="महिला">महिला</option>
                      <option value="अन्य">अन्य</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mySelectElement">मोबाइल नं</label>
                    <input
                      type="text"
                      id="mySelectElement"
                      placeholder="मोबाइल नं"
                      name="mobileNo"
                      value={formData.defendent.mobileNo}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "mobileNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizenshipNo">नागरिकता नं</label>
                    <input
                      type="text"
                      id="citizenshipNo"
                      name="citizenshipNo"
                      placeholder="नागरिकता नं"
                      value={formData.defendent.citizenshipNo}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "citizenshipNo")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="citizinshipIssuedDate">
                      नागरिकता जारी मिति
                    </label>
                    <input
                      type="text"
                      id="citizinshipIssuedDate"
                      name="citizinshipIssuedDate"
                      placeholder="नागरिकता जारी मिति"
                      value={formData.defendent.citizinshipIssuedDate}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "defendent",
                          "citizinshipIssuedDate"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="grandFathersName">बाजेको नाम</label>
                    <input
                      type="text"
                      id="grandFathersName"
                      name="grandFathersName"
                      placeholder="बाजेको नाम"
                      value={formData.defendent.grandFathersName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "grandFathersName")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fathersName">बाबुको नाम</label>
                    <input
                      type="text"
                      id="fathersName"
                      name="fathersName"
                      placeholder="बाबुको नाम"
                      value={formData.defendent.fathersName}
                      onChange={(e) =>
                        handleInputChange(e, "defendent", "fathersName")
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="defendentTemporaryProvince">
                      अस्थायी प्रदेश
                    </label>
                    <select
                      type="text"
                      id="defendentTemporaryProvince"
                      name="province"
                      value={formData.defendent.temporaryAddress.province}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "province"
                        )
                      }
                    >
                      <option value="प्रदेश">
                        --अस्थायी प्रदेश छान्नुहोस्--
                      </option>
                      <option value="कोशी">कोशी प्रदेश</option>
                      <option value="मधेश">मधेश प्रदेश</option>
                      <option value="बाग्मती">बाग्मती प्रदेश</option>
                      <option value="गण्डकी">गण्डकी प्रदेश</option>
                      <option value="लुम्बिनी">लुम्बिनी प्रदेश</option>
                      <option value="कर्णाली">कर्णाली प्रदेश</option>
                      <option value="सुदूरपश्चिम">सुदूरपश्चिम प्रदेश</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      placeholder="जिल्ला"
                      value={formData.defendent.temporaryAddress.district}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "district"
                        )
                      }
                    >
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="municipality">नगरपालिका/गाउँपालिका</label>
                    <select
                      type="text"
                      id="municipality"
                      name="municipality"
                      placeholder="नगरपालिका/गाउँपालिका"
                      value={formData.defendent.temporaryAddress.municipality}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "municipality"
                        )
                      }
                      disabled={!filteredMunicipalities.length}
                    >
                      <option value="">
                        नगरपालिका/गाउँपालिका चयन गर्नुहोस्
                      </option>
                      {filteredMunicipalities.map((municipality) => (
                        <option key={municipality} value={municipality}>
                          {municipality}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentTemporaryWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="defendentTemporaryWardNo"
                      name="defendentTemporaryWardNo"
                      placeholder="वडा नं"
                      value={formData.defendent.temporaryAddress.wardNo}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "temporaryAddress",
                          "wardNo"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="defendentPermanentProvince">
                      स्थायी प्रदेश
                    </label>
                    <select
                      type="text"
                      id="defendentPermanentProvince"
                      name="defendentPermanentProvince"
                      value={formData.defendent.permanentAddress.province}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "province"
                        )
                      }
                    >
                      <option value="प्रदेश">
                        --स्थायी प्रदेश छान्नुहोस्--
                      </option>
                      <option value="कोशी">कोशी प्रदेश</option>
                      <option value="मधेश">मधेश प्रदेश</option>
                      <option value="बाग्मती">बाग्मती प्रदेश</option>
                      <option value="गण्डकी">गण्डकी प्रदेश</option>
                      <option value="लुम्बिनी">लुम्बिनी प्रदेश</option>
                      <option value="कर्णाली">कर्णाली प्रदेश</option>
                      <option value="सुदूरपश्चिम">सुदूरपश्चिम प्रदेश</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="district">जिल्ला</label>
                    <select
                      id="district"
                      name="district"
                      placeholder="जिल्ला"
                      value={formData.defendent.permanentAddress.district}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "district"
                        )
                      }
                    >
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentPermanentMunicipality">
                      नगरपालिका/गाउँपालिका
                    </label>
                    <select
                      type="text"
                      id="defendentPermanentMunicipality"
                      name="municipality"
                      placeholder="नगरपालिका/गाउँपालिका"
                      value={formData.defendent.permanentAddress.municipality}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "municipality"
                        )
                      }
                      disabled={!filteredMunicipalities.length}
                    >
                      <option value="">
                        नगरपालिका/गाउँपालिका चयन गर्नुहोस्
                      </option>
                      {filteredMunicipalities.map((municipality) => (
                        <option key={municipality} value={municipality}>
                          {municipality}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="defendentPermanentWardNo">वडा नं</label>
                    <input
                      type="text"
                      id="defendentPermanentWardNo"
                      name="wardNo"
                      placeholder="वडा नं"
                      value={formData.defendent.permanentAddress.wardNo}
                      onChange={(e) =>
                        handleAddressChange(
                          e,
                          "defendent",
                          "permanentAddress",
                          "wardNo"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-section">
                <h2>मामिलाको विवरण</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="issue">मुद्दा</label>
                    <select
                      id="issue"
                      value={formData.caseDescription.lawSuit}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "lawSuit")
                      }
                    >
                      <option value="">-- मुद्दा छान्नुहोस् --</option>
                      <option value="">Sima Bibadh</option>
                      <option value="">Haadakuti</option>
                      <option value="">सार्बजनिक बाटो</option>
                      <option value="">४७(२) को ट</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="type">मुद्दाको प्रकार</label>
                    <select
                      id="type"
                      value={formData.caseDescription.type}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "type")
                      }
                    >
                      <option value="">-- मुद्दाको प्रकार छान्नुहोस् --</option>
                      <option value="si">Samanya</option>
                      <option value="had">सार्बजनिक बाटो</option>
                      <option value="sab">४७(२) को ट</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registerDate">दर्ता मिति</label>
                    <NepaliDatePicker
                      value={formData.caseDescription.registeredDate}
                      format="YYYY-MM-DD"
                      placeholder="दर्ता मिति"
                      onChange={(date) =>
                        handleDateChange(
                          date,
                          "caseDescription",
                          "registeredDate"
                        )
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText="२०८१-०३-२३"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="stage">मुद्दाको चरण</label>
                    <select
                      id="stage"
                      value={formData.caseDescription.stage}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "stage")
                      }
                    >
                      <option value="">-- मुद्दाको चरण छान्नुहोस् --</option>
                      {casePhases.map((phase, index) => (
                        <option key={index} value={phase}>
                          {phase}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="filingNumber">फाइलिङ नम्बर</label>
                    <input
                      type="text"
                      id="filingNumber"
                      placeholder="फाइलिङ नम्बर"
                      name="fileNo"
                      value={formData.caseDescription.fileNo}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "fileNo")
                      }
                    />
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="filingDate">फाइलिङ मिति</label>
                    <NepaliDatePicker
                      value={formData.caseDescription.registeredDate}
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(
                          date,
                          "caseDescription",
                          "registeredDate"
                        )
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText="२०८१-०३-२३"
                    />
                  </div> */}
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="firstHearingDate">पहिलो तारिकको मिति</label>
                    <NepaliDatePicker
                      value={formData.caseDescription.firstDateOfPresence}
                      format="YYYY-MM-DD"
                      placeholder="YYYY-MM-DD"
                      onChange={(date) =>
                        handleDateChange(
                          date,
                          "caseDescription",
                          "firstDateOfPresence"
                        )
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText="२०८१-०३-२३"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="hearingTime">तारिक समय</label>
                    <input
                      type="time"
                      id="hearingTime"
                      placeholder="तारिक समय"
                      name="timeOfPresence"
                      value={formData.caseDescription.timeOfPresence}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "caseDescription",
                          "timeOfPresence"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationNo">दर्ता नम्बर</label>
                    <input
                      type="text"
                      id="registrationNo"
                      name="registrationNo"
                      placeholder="दर्ता नम्बर"
                      value={formData.caseDescription.registrationNumber}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "caseDescription",
                          "registrationNo"
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationFee">दर्ता दस्तुर</label>
                    <input
                      type="text"
                      id="registrationFee"
                      name="registrationFee"
                      placeholder="दर्ता दस्तुर"
                      value={formData.caseDescription.registrationFee}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "caseDescription",
                          "registrationFee"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="subjectOfCase">विवादको बिषय</label>
                  <input
                    type="text"
                    id="subjectOfCase"
                    name="subjectOfCase"
                    placeholder="विवादको बिषय"
                    value={formData.caseDescription.subjectOfCase}
                    onChange={(e) =>
                      handleInputChange(e, "caseDescription", "subjectOfCase")
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">विवरण</label>
                  <JoditEditor
                    value={formData.caseDescription.description}
                    tabIndex={1}
                    onChange={(newContent) =>
                      handleInputChange(
                        { target: { value: newContent } },
                        "caseDescription",
                        "description"
                      )
                    }
                  />
                </div>
              </div>

              <hr />

              {/* Judicial Details */}
              <div className="form-section">
                <h2>न्यायिक समितिको विवरण</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="type">अदालतको प्रकार</label>
                    <select
                      id="type"
                      name="type"
                      value={formData.judicialDetails.type}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "type")
                      }
                    >
                      <option value="">-- अदालतको प्रकार छान्नुहोस् --</option>
                      <option value="kailali">Jilla Adaalat</option>
                      <option value="budiganga">न्यायिक समिति</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="court">अदालत</label>
                    <select
                      id="court"
                      name="court"
                      value={formData.judicialDetails.court}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "court")
                      }
                    >
                      <option value="">-- अदालत छान्नुहोस् --</option>
                      <option value="kailali">Kailali Jilla Adaalat</option>
                      <option value="budiganga">
                        बूढीगंगा गाउँपालिका,कानूनी मामिला प्रणाली
                      </option>
                    </select>
                  </div>
                  {/* Add other fields similarly */}
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="judgeName">न्यायाधीशको नाम</label>
                    <select
                      id="judgeName"
                      name="judgeName"
                      value={formData.judicialDetails.judgeName}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "judgeName")
                      }
                    >
                      <option value="">-- न्यायधिसको नाम छान्नुहोस् --</option>
                      <option value="kailali">Ramesh Chapagain</option>
                      <option value="budiganga">विकास डुम्राकोटी</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="judgePosition">न्यायधिसको पद</label>
                    <select
                      id="judgePosition"
                      name="judgePosition"
                      value={formData.judicialDetails.judgePosition}
                      onChange={(e) =>
                        handleInputChange(e, "judicialDetails", "judgePosition")
                      }
                    >
                      <option value="">-- न्यायाधीशको पद छान्नुहोस् --</option>
                      <option value="judge">Judge</option>
                      <option value="samyojak">संयोजक</option>
                      <option value="sadasdhya">सदस्य</option>
                    </select>
                  </div>
                  {/* Add other fields similarly */}
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="description">कैफियत</label>
                    <input
                      type="text"
                      id="description"
                      placeholder="कैफियत"
                      value={formData.caseDescription.description}
                      onChange={(e) =>
                        handleInputChange(e, "caseDescription", "description")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button type="submit" onClick={showToast}>
                  बुझाउनुहोस्
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dartaform;
