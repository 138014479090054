import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { NepaliDatePicker } from "nepali-datepicker-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../components/Navbar";

const EditPage = ({ onSave }) => {
  const { caseId } = useParams();

  const [plaintiff, setPlaintiff] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    age: "",
    sex: "",
    mobileNo: "",
    citizenshipNo: "",
    citizenshipIssuedDate: "",
    grandFathersName: "",
    fathersName: "",
    temporaryAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
    permanentAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
  });

  const [defendent, setDefendent] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    age: "",
    sex: "",
    mobileNo: "",
    citizenshipNo: "",
    citizenshipIssuedDate: "",
    grandFathersName: "",
    fathersName: "",
    temporaryAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
    permanentAddress: {
      province: "",
      district: "",
      municipality: "",
      wardNo: "",
    },
  });

  const [caseDescription, setCaseDescription] = useState({
    lawSuit: "",
    type: "",
    registeredDate: "",
    stage: "",
    fileNo: "",
    firstDateOfPresence: "",
    timeOfPresence: "",
    registrationNo: "",
    registrationFee: "",
    subjectOfCase: "",
    description: "",
    witness: "",
  });

  const [judicialDetails, setjudicialDetails] = useState({
      type: "",
      court: "",
      judgeName: "",
      judgePosition: "",
      description: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const response = await axios.get(
          `https://backend.bardagoriyalegalsystem.com/api/v1/admin/case?caseId=${caseId}`
        );
        const caseData = response.data.case;
        setPlaintiff(caseData.plaintiff || {});
        setDefendent(caseData.defendent || {});
        setCaseDescription(caseData.caseDescription || {});
        setjudicialDetails(caseData.judicialDetails || {});
      } catch (error) {
        console.error("Error fetching case data", error);
      }
    };
    if (caseId) {
      fetchCaseData();
    }
  }, [caseId]);

  const handleChange = (e, state, setState) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddressChange = (e, addressType, state, setState) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [addressType]: { ...prev[addressType], [name]: value },
    }));
  };

  const handleDateChange = (date, field, stateType, setState) => {
    const formattedDate = date;
    const age = date ? calculateAge(date) : "";
    setState((prev) => ({
      ...prev,
      [field]: formattedDate,
      ...(stateType === "plaintiff" ? { age } : {}),
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updatedCase = {
      plaintiff,
      defendent,
      caseDescription,
      judicialDetails,
    };

    try {
      const response = await axios.put(
        `https://backend.bardagoriyalegalsystem.com/api/v1/admin/updateCase/${caseId}`,
        updatedCase
      );
      toast.success("Case updated successfully!");
      if (onSave) onSave(response.data);
      window.location.href = "/muddadarta";
    } catch (error) {
      console.error("Error saving case data", error);
      if (error.response && error.response.data) {
        toast.error(
          error.response.data.message ||
            "Failed to save case data. Please try again."
        );
        setErrors({
          saveError:
            error.response.data.message ||
            "Failed to save case data. Please try again.",
        });
      } else {
        toast.error("Failed to save case data. Please try again.");
        setErrors({ saveError: "Failed to save case data. Please try again." });
      }
    }
  };

  const casePhases = ["मुद्दा दर्ता", "प्रक्रिया", "तामेली", "सम्पन्न"];

  const calculateAge = (dob) => {
    if (!dob) return "";
    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  if (!plaintiff || !defendent || !caseDescription) {
    return <p>Loading...</p>;
  }

  return (
    <div>
    <NavBar />
    <div className="container-edit">
      <h2 className="h2">Edit Case</h2>
      <style>{` /* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #f0f2f5;
  {/* padding: 20px; */}
}

.container-edit {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  margin-top: 30px;
}

.h2, .h3, .h4 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  border-bottom: 2px solid #b83955;
  padding-bottom: 10px;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  color: #0f83b4;
  margin-bottom: 8px;
  font-size: 14px;
}

input[type="text"],
input[type="number"],
select,
textarea {
  padding: 12px 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
  border-color: #0f83b4;
  background-color: #fff;
  outline: none;
}

input[type="text"],
textarea {
  width: 100%;
}

textarea {
  height: 100px;
}

button {
  grid-column: span 2;
  padding: 12px 20px;
  background-color: #ff4b5c;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #d43c48;
}

.nepali-datepicker {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #ccc;
  transition: border-color 0.3s ease;
}

.nepali-datepicker:focus {
  border-color: #007bff;
  background-color: #fff;
}

.error-message {
  color: #ff4b5c;
  font-size: 13px;
  margin-top: 5px;
}

.address-section h4 {
  font-size: 20px;
  color: #ff4b5c;
  margin-bottom: 15px;
  border-bottom: 1px solid #ff4b5c;
  padding-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  form {
    grid-template-columns: 1fr;
  }

  button {
    grid-column: span 1;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`}</style>
      <form onSubmit={handleSave}>
        <h3 className="h3">प्रथम पक्ष</h3>
        <div>
          <label>पहिलो नाम</label>
          <input
            type="text"
            name="firstName"
            value={plaintiff.firstName}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>बिचको नाम</label>
          <input
            type="text"
            name="middleName"
            value={plaintiff.middleName}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>थर</label>
          <input
            type="text"
            name="lastName"
            value={plaintiff.lastName}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>जन्म मिति</label>
          <NepaliDatePicker
            value={plaintiff.dob}
            format="YYYY-MM-DD"
            onChange={(date) =>
              handleDateChange(date, "dob", "plaintiff", setPlaintiff)
            }
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>उमेर</label>
          <input type="text" name="age" value={plaintiff.age} readOnly />
        </div>
        <div>
          <label>लिङ्ग</label>
          <input
            type="text"
            name="sex"
            value={plaintiff.sex}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>मोबाइल नं</label>
          <input
            type="text"
            name="mobileNo"
            value={plaintiff.mobileNo}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>नागरिकता नं</label>
          <input
            type="text"
            name="citizenshipNo"
            value={plaintiff.citizinshipNo}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>नागरिकता जारी मिति</label>
          <NepaliDatePicker
            value={plaintiff.citizinshipIssuedDate}
            format="YYYY-MM-DD"
            onChange={(date) =>
              handleDateChange(
                date,
                "citizinshipIssuedDate",
                "plaintiff",
                setPlaintiff
              )
            }
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>बाजेको नाम</label>
          <input
            type="text"
            name="grandFathersName"
            value={plaintiff.grandFathersName}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <div>
          <label>बाबुको नाम</label>
          <input
            type="text"
            name="fathersName"
            value={plaintiff.fathersName}
            onChange={(e) => handleChange(e, plaintiff, setPlaintiff)}
          />
        </div>
        <h4 className="h4">अस्थायी ठेगाना</h4>
        <div>
          <label>प्रदेश</label>
          <input
            type="text"
            name="province"
            value={plaintiff.temporaryAddress.province}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <div>
          <label>जिल्ला</label>
          <input
            type="text"
            name="district"
            value={plaintiff.temporaryAddress.district}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <div>
          <label>नगरपालिका/गाउँपालिका</label>
          <input
            type="text"
            name="municipality"
            value={plaintiff.temporaryAddress.municipality}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <div>
          <label>वडा नं</label>
          <input
            type="text"
            name="wardNo"
            value={plaintiff.temporaryAddress.wardNo}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <h4 className="h4">स्थायी ठेगाना</h4>
        <div>
          <label>प्रदेश</label>
          <input
            type="text"
            name="province"
            value={plaintiff.permanentAddress.province}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <div>
          <label>जिल्ला</label>
          <input
            type="text"
            name="district"
            value={plaintiff.permanentAddress.district}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <div>
          <label>नगरपालिका/गाउँपालिका</label>
          <input
            type="text"
            name="municipality"
            value={plaintiff.permanentAddress.municipality}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>
        <div>
          <label>वडा नं</label>
          <input
            type="text"
            name="wardNo"
            value={plaintiff.permanentAddress.wardNo}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                plaintiff,
                setPlaintiff
              )
            }
          />
        </div>

        <h3 className="h3">प्रतिवादी</h3>
        <div>
          <label>पहिलो नाम</label>
          <input
            type="text"
            name="firstName"
            value={defendent.firstName}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>बिचको नाम</label>
          <input
            type="text"
            name="middleName"
            value={defendent.middleName}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={defendent.lastName}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>जन्म मिति</label>
          <NepaliDatePicker
            value={defendent.dob}
            format="YYYY-MM-DD"
            onChange={(date) =>
              handleDateChange(date, "dob", "defendent", setDefendent)
            }
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>उमेर</label>
          <input type="text" name="age" value={defendent.age} readOnly />
        </div>
        <div>
          <label>लिङ्ग</label>
          <input
            type="text"
            name="sex"
            value={defendent.sex}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>मोबाइल नं</label>
          <input
            type="text"
            name="mobileNo"
            value={defendent.mobileNo}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>नागरिकता नं</label>
          <input
            type="text"
            name="citizenshipNo"
            value={defendent.citizinshipNo}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>नागरिकता जारी मिति</label>
          <NepaliDatePicker
            value={defendent.citizenshipIssuedDate}
            format="YYYY-MM-DD"
            onChange={(date) =>
              handleDateChange(
                date,
                "citizenshipIssuedDate",
                "defendent",
                setDefendent
              )
            }
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>बाजेको नाम</label>
          <input
            type="text"
            name="grandFathersName"
            value={defendent.grandFathersName}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <div>
          <label>बाबुको नाम</label>
          <input
            type="text"
            name="fathersName"
            value={defendent.fathersName}
            onChange={(e) => handleChange(e, defendent, setDefendent)}
          />
        </div>
        <h4 className="h4">अस्थायी ठेगाना</h4>
        <div>
          <label>प्रदेश</label>
          <input
            type="text"
            name="province"
            value={defendent.temporaryAddress.province}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <div>
          <label>जिल्ला</label>
          <input
            type="text"
            name="district"
            value={defendent.temporaryAddress.district}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <div>
          <label>नगरपालिका/गाउँपालिका</label>
          <input
            type="text"
            name="municipality"
            value={defendent.temporaryAddress.municipality}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <div>
          <label>वडा नं</label>
          <input
            type="text"
            name="wardNo"
            value={defendent.temporaryAddress.wardNo}
            onChange={(e) =>
              handleAddressChange(
                e,
                "temporaryAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <h4 className="h4">स्थायी ठेगाना</h4>
        <div>
          <label> प्रदेश</label>
          <input
            type="text"
            name="province"
            value={defendent.permanentAddress.province}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <div>
          <label>जिल्ला</label>
          <input
            type="text"
            name="district"
            value={defendent.permanentAddress.district}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <div>
          <label>नगरपालिका/गाउँपालिका</label>
          <input
            type="text"
            name="municipality"
            value={defendent.permanentAddress.municipality}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>
        <div>
          <label>वडा नं</label>
          <input
            type="text"
            name="wardNo"
            value={defendent.permanentAddress.wardNo}
            onChange={(e) =>
              handleAddressChange(
                e,
                "permanentAddress",
                defendent,
                setDefendent
              )
            }
          />
        </div>

        <h3 className="h3">मामिलाको विवरण</h3>
        <div>
          <label>मुद्दा</label>
          <input
            type="text"
            name="lawSuit"
            value={caseDescription.lawSuit}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        <div>
          <label>-- मुद्दा छान्नुहोस् --</label>
          <input
            type="text"
            name="type"
            value={caseDescription.type}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        <div>
          <label>दर्ता मिति</label>
          <NepaliDatePicker
            value={caseDescription.registeredDate}
            format="YYYY-MM-DD"
            onChange={(date) =>
              handleDateChange(
                date,
                "registeredDate",
                "caseDescription",
                setCaseDescription
              )
            }
            placeholderText="YYYY-MM-DD"
          />
        </div>
        <div>
          <label>मुद्दाको चरण</label>
          <select
            name="stage"
            value={caseDescription.stage}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          >
            <option value="">-- मुद्दाको चरण छान्नुहोस् --</option>
            {casePhases.map((phase, index) => (
              <option key={index} value={phase}>
                {phase}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>फाइलिङ नम्बर</label>
          <input
            type="text"
            name="fileNo"
            value={caseDescription.fileNo}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        {/* <div>
          <label>फाइलिङ मिति</label>
          <NepaliDatePicker
            value={caseDescription.registeredDate}
            format="YYYY-MM-DD"
            onChange={(date) =>
              handleDateChange(
                date,
                "registeredDate",
                "caseDescription",
                setCaseDescription
              )
            }
            placeholderText="YYYY-MM-DD"
          />
        </div> */}
        <div>
          <label>पहिलो तारिकको मिति</label>
          <input
            type="text"
            name="firstDateOfPresence"
            value={caseDescription.firstDateOfPresence}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        <div>
          <label>तारिक समय</label>
          <input
            type="text"
            name="timeOfPresence"
            value={caseDescription.timeOfPresence}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        <div>
          <label>दर्ता नम्बर</label>
          <input
            type="text"
            name="registrationNo"
            value={caseDescription.registrationNo}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        <div>
          <label>दर्ता दस्तुर</label>
          <input
            type="text"
            name="registrationFee"
            value={caseDescription.registrationFee}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>
        <div>
          <label>विवादको बिषय</label>
          <input
            type="text"
            name="subjectOfCase"
            value={caseDescription.subjectOfCase}
            onChange={(e) =>
              handleChange(e, caseDescription, setCaseDescription)
            }
          />
        </div>

        {/* Judicial Details */}
        <h3 className="h3">न्यायिक समितिको विवरण</h3>

        <div>
          <label>अदालतको प्रकार</label>
          <input
          type="text"
            name="type"
            value={judicialDetails.type}
            onChange={(e) =>
              handleChange(e, judicialDetails, setjudicialDetails)
            }
          />
        </div>

        <div>
          <label>अदालत</label>
          <input
          type="text"
            name="court"
            value={judicialDetails.court}
            onChange={(e) =>
              handleChange(e, judicialDetails, setjudicialDetails)
            }
          />
        </div>

        <div>
          <label>न्यायधिसको नाम</label>
          <input
            type="text"
            name="judgeName"
            value={judicialDetails.judgeName}
            onChange={(e) =>
              handleChange(e, judicialDetails, setjudicialDetails)
            }
          />
        </div>

        <div>
          <label>न्यायधिसको पद</label>
          <input
            type="text"
            name="judgePosition"
            value={judicialDetails.judgePosition}
            onChange={(e) =>
              handleChange(e, judicialDetails, setjudicialDetails)
            }
          />

        </div>

        <div>
          <label>कैफियत</label>
          <textarea
            name="description"
            value={judicialDetails.description}
            onChange={(e) =>
              handleChange(e, judicialDetails, setjudicialDetails)
            }
          />
        </div>

        <button type="submit">Submit</button>
      </form>
      <ToastContainer />
    </div>
    </div>
  );
};

export default EditPage;
