import { Link, useLocation } from "react-router-dom";
import "../assets/styles/navigation_menu.css";
import React, { useState } from "react";

export default function NavigationMenu() {
  const [isDisputeOpen, setDisputeOpen] = useState(true); // Initially open
  const [isReportOpen, setReportOpen] = useState(true); // Initially open
  const [isCollapsed, setCollapsed] = useState(false); // Track sidebar collapse

  const location = useLocation(); // Hook to get the current path

  const toggleSidebar = () => {
    setCollapsed(!isCollapsed);
  };

  // Set dynamic content based on current location
  const renderSubNavbar = () => {
    switch (location.pathname) {
      case "/":
        return "गृहपृष्ठ";
      case "/muddadarta":
        return "मुद्दा दर्ता";
      case "/prakriya":
        return "प्रक्रिया भएका मुद्दाका सूची";
      case "/tameli":
        return "तामेली भएका मुद्दाका सूची";
      case "/sampanna":
        return "सम्पन्न भएका मुद्दाका सूची";
      case "/muddadarta-pustika":
        return "मुद्दा दर्ता पुस्तिका";
      case "/darta":
        return "मुद्दा/मामिला दर्ता";
      default:
        return "बर्दगोरिया गाउँपालिका";
    }
  };

  const isActive = (path) => location.pathname === path; // Function to check if the path is active

  return (
    <div className={`app-container ${isCollapsed ? "collapsed" : ""}`}>
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <div className="collapse-icon-wrapper">
          <button className="collapse-btn" onClick={toggleSidebar}>
            {isCollapsed ? "▶" : "◀"}
          </button>
        </div>

        <button className={`sidebar-btn`}>
          <Link to="/">गृहपृष्ठ</Link>
        </button>

        <button
          className={`sidebar-btn `}
          onClick={() => setDisputeOpen(!isDisputeOpen)}
        >
          विवाद / उजुरी {isDisputeOpen ? "▲" : "▼"}
        </button>
        {isDisputeOpen && (
          <div className="sidebar-dropdown">
            <button className={`sidebar-sub-btn`}>
              <Link className="link" to="/muddadarta">मुद्दा दर्ता</Link>
            </button>
            <button className={`sidebar-sub-btn `}>
              <Link to="/prakriya">प्रक्रिया भएका मुद्दाका सूची</Link>
            </button>
            <button className={`sidebar-sub-btn `}>
              <Link to="/tameli">तामेली भएका मुद्दाका सूची</Link>
            </button>
            <button className={`sidebar-sub-btn `}>
              <Link to="/sampanna">सम्पन्न भएका मुद्दाका सूची</Link>
            </button>
          </div>
        )}

        <button
          className={`sidebar-btn ${isActive("/muddadarta-pustika") ? "active" : ""}`}
          onClick={() => setReportOpen(!isReportOpen)}
        >
          रिपोर्ट {isReportOpen ? "▲" : "▼"}
        </button>
        {isReportOpen && (
          <div className="sidebar-dropdown">
            <button className={`sidebar-sub-btn ${isActive("/muddadarta-pustika") ? "active" : ""}`}>
              <Link to="/muddadarta-pustika">मुद्दा दर्ता पुस्तिका</Link>
            </button>
          </div>
        )}
      </div>

      {/* Dynamic Sub-navbar */}
      <div className="sub-navbar">
        <div className="nav-item">{renderSubNavbar()}</div>
        <div className="search-bar">
          <input type="text" placeholder="Search..." />
          <button className="search-btn">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
